<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />
    <!--    <content-not-view-->
    <!--        v-else-if="-->
    <!--        (!subPermission.store && !isEdit) || (!subPermission.update && isEdit)-->
    <!--      "-->
    <!--    />-->

    <b-card v-else>
      <validation-observer ref="form">
        <b-form>
          <b-row>
            <!-- Name -->
            <b-col md="12">
              <b-form-group :label="$t('settings/roles/name')" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="
                      $t('settings/placeholder/enter_the_name_here')
                    "
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Name -->

            <!-- permissions -->
            <div>
              <b-col
                cols="12"
                v-for="(item, index) in permissions"
                :key="index"
              >
                <div class="permission">
                  <h5 class="permission-name">
                    {{ $t(`g.roles_page.${item.name}`) }}
                  </h5>

                  <div class="sub-permissions">
                    <div v-for="(ele, i) in item.subPermissions" :key="i">
                      <div class="sub-permission">
                        <b-form-checkbox
                          :value="ele.id"
                          :name="ele.name"
                          v-model="form_data.sub_permissions"
                        >
                          {{ $t(`g.sub_roles_page.${ele.name}`) }}
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </div>
            <!-- ** permissions -->

            <!-- Submit Button -->
            <b-col cols="12">
              <b-button
                :variant="isEdit ? 'success' : 'primary'"
                type="submit"
                @click.prevent="validationForm"
                :disabled="buttonIsDisabled"
              >
                {{ isEdit ? $t("g.edit") : $t("g.submit") }}
              </b-button>
            </b-col>
            <!-- ** Submit Button -->
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Loading from "@/components/shared/loading/loading.vue";
import Error from "@/components/error/error.vue";
import ContentNotView from "@/components/contentNotView/contentNotView.vue";
import store from "@/store";

import {
  BFormGroup,
  BFormCheckbox,
  BForm,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormInput,
} from "bootstrap-vue";

export default {
  components: {
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BCard,
    BRow,
    BCol,
    Error,
    Loading,
    ContentNotView,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      form_data: {
        name: "",
        sub_permissions: [],
      },
      permissions: [],
      isEdit: false,
      isLoading: true,
      isError: false,
      role_id: null,
      buttonIsDisabled: false,
      roleName: null,
    };
  },
  watch: {
    $route(to, from) {
      if (to.name === "add-role") {
        this.role_id = null;
        this.isEdit = false;
        this.form_data.name = "";
        this.form_data.sub_permissions = [];
      }
    },
  },
  computed: {
    // subPermission() {
    //   this.$store.dispatch("GET_PERMISSION", "roles");
    //   return this.$store.state.userData.sub_Permission;
    // },
  },
  beforeCreate() {
    if (this.$route.name === "edit-role" && this.$route.params.id === "1") {
      this.$router.push({ name: "permission-denied" });
    }
  },
  beforeMount() {
    if (this.$route.params.id) {
      const id = this.$route.params.id;
      this.role_id = id;
      this.isEdit = true;
      this.$http(`admin/roles/${id}?guard=web`)
        .then((response) => {
          if (response.status === 200) {
            const res = response.data.data;

            res.permissions.forEach((item) =>
              item.subPermissions.forEach((ele) => {
                if (ele.is_checked) {
                  this.form_data.sub_permissions.push(ele.id);
                }
              })
            );
            this.roleName = res.name;
            this.form_data.name = res.name;
            this.permissions = res?.permissions;

            this.pageStatus(false, false);
          }
        })
        .catch((error) => {
          this.$helpers.handleError(error);

          this.pageStatus(false, true);
        });
    } else {
      this.pageStatus(false, false);
    }
  },

  methods: {
    // Changes the state of the page according to the return of the data => ( success Or error )
    pageStatus(loading, error) {
      this.isLoading = loading;
      this.isError = error;
    },
    submitNewRole() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          // append data to FormData object before sending to backend
          let formData = new FormData();

          formData.append("name", this.form_data.name);
          // disabled form button
          this.buttonIsDisabled = true;
          this.$http
            .post("admin/roles", formData)
            .then((res) => {
              this.$helpers.makeToast(
                "success",
                res.data.message,
                res.data.message
              );
              setTimeout(() => {
                this.$router.push({ name: "list-roles" });
              }, 1000);
            })
            .catch((err) => {
              this.buttonIsDisabled = false;
              this.$helpers.handleError(err);
            });
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
    submitEditRole() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          let formData = new FormData();
          for (let item in this.form_data.sub_permissions) {
            formData.append(
              `sub_permissions[${item}]`,
              this.form_data.sub_permissions[item]
            );
          }
          formData.append("name", this.form_data.name);
          // disabled form button
          this.buttonIsDisabled = true;
          this.$http
            .post(`admin/roles/${this.role_id}?_method=put`, formData)
            .then((res) => {
              this.$helpers.makeToast(
                "success",
                res.data.message,
                res.data.message
              );
              setTimeout(() => {
                this.$router.push({ name: "list-roles" });
              }, 1000);
            })
            .catch((err) => {
              this.buttonIsDisabled = false;
              this.$helpers.handleError(err);
            });
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
    validationForm() {
      if (this.isEdit) {
        return this.submitEditRole();
      }
      this.submitNewRole();
    },
  },
};
</script>

<style lang="scss" scoped src="./_index.scss" />
